import { useState, useEffect } from 'react'

import { ELOPAGE_CABINETS } from '@elo-kit/constants/general.constants'
import { SCREEN_SIZE } from 'constants/general.constants'
import { EMAIL_REGEX } from '@elo-kit/constants/regex.constants'

import { setTokens } from 'utils/requests.utils'

import { notify } from 'libs/common/notify'
import { isWindowEnv } from 'utils/env.utils'

import { useNextRouter } from 'shop/hooks/use-next-js-router'
import { useShopStores } from 'shop/hooks/use-store'
import { patchLink } from 'utils/link.utils'

import { SignInParams, SignInData, signin } from '../../../../js/containers/app/api/session.api'
import { sendMagicLink } from '../../../../js/containers/app/api/user.api'

interface Touched {
  email?: boolean
  password?: boolean
}

interface FormData {
  email: string
  password: string
  loginViaMagicLink: boolean
  isMagicLinkExpired: string | string[] | boolean
  isMagicLinkSent: boolean
}

const defaultFormData = {
  email: '',
  password: '',
  loginViaMagicLink: false,
  isMagicLinkExpired: false,
  isMagicLinkSent: false,
}

interface SearchParams {
  magic_link_token: string
  return_to: string
}

export const useSignIn = (screenSize: string) => {
  const { sellerStore } = useShopStores()
  const { isAppActive, item: seller } = sellerStore
  const { username } = seller || {}
  const [data, setData] = useState<FormData>(defaultFormData)
  const [touched, setTouched] = useState<Touched>({})
  const [loading, setLoading] = useState(false)

  const [mobileView, setMobileView] = useState(
    isWindowEnv() ? window.innerWidth < SCREEN_SIZE.tablet : screenSize === 'mobile'
  )

  const { email, password, loginViaMagicLink, isMagicLinkExpired, isMagicLinkSent } = data
  const isEmailValid = EMAIL_REGEX.test(email)
  const isPasswordValid = !!password.length
  const isValid = loginViaMagicLink ? isEmailValid : isEmailValid && isPasswordValid

  const { params: queryParams } = useNextRouter<SearchParams>()

  const signIn = async (sendData: SignInData, params: SignInParams = {}) => {
    const { magicLinkToken } = sendData
    setLoading(true)
    const resp = await signin(sendData, params)
    const { redirectUrl, success } = resp

    if (success) {
      await setTokens(resp)
      notify('success', I18n.t('react.shared.notify.success'))
      const payerShopUrl = `/payer/s/${username}`
      const sessionStorage = isWindowEnv() && navigator.cookieEnabled && window.sessionStorage
      const returnTo = queryParams?.return_to
      const refererLink = sessionStorage ? window.sessionStorage.getItem('referer_link') : ''

      window.location.href = patchLink(redirectUrl || refererLink || returnTo || payerShopUrl)

      if (sessionStorage) {
        sessionStorage.removeItem('referer_link')
      }
    } else {
      setData({
        ...data,
        isMagicLinkExpired: magicLinkToken,
      })
      setLoading(false)
    }
  }

  const handleResize = () => setMobileView(window.innerWidth < SCREEN_SIZE.tablet)

  useEffect(() => {
    const magicLinkToken = queryParams.magic_link_token

    if (magicLinkToken) {
      signIn({ magicLinkToken }, { skipErrorNotific: true })
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleInput = (name: string, value: string) => {
    setData({
      ...data,
      [name]: value,
    })

    if (!touched[name]) {
      setTouched((touched) => ({
        ...touched,
        [name]: true,
      }))
    }
  }

  const toggleMagicLink = () => {
    setData({
      ...data,
      loginViaMagicLink: !loginViaMagicLink,
      isMagicLinkExpired: false,
      isMagicLinkSent: false,
    })
  }

  const handleSubmit = async () => {
    if (isValid) {
      if (data.loginViaMagicLink) {
        const { success } = await sendMagicLink(ELOPAGE_CABINETS.payer, email, username)
        if (success) {
          notify('success', I18n.t('react.shop.auth.sign_in.sent_link'))
          setData({
            ...data,
            isMagicLinkSent: true,
          })
        }
      } else {
        await signIn({
          profileType: ELOPAGE_CABINETS.payer,
          email,
          password,
        })
      }
    }
  }

  const linkNotExpiredAndNotSent = !isMagicLinkSent && !isMagicLinkExpired

  const sentOrExpiredFormTitle =
    isMagicLinkExpired && !isMagicLinkSent
      ? I18n.t('react.shop.auth.sign_in.try_again')
      : I18n.t('react.shop.auth.sign_in.sent_link')
  const magicLinkFormTitle = loginViaMagicLink
    ? I18n.t('react.shop.auth.sign_in.magic_link_title')
    : I18n.t('react.shop.auth.sign_in.title')
  const formTitle = linkNotExpiredAndNotSent ? magicLinkFormTitle : sentOrExpiredFormTitle

  const sentOrExpireDescription =
    isMagicLinkExpired && !isMagicLinkSent
      ? I18n.t('react.shop.auth.sign_in.try_again_description')
      : I18n.t('react.shop.auth.sign_in.sent_link_description')
  const signInDescription = linkNotExpiredAndNotSent
    ? I18n.t('react.shop.auth.sign_in.title_description')
    : sentOrExpireDescription
  const formDescription =
    loginViaMagicLink && linkNotExpiredAndNotSent
      ? I18n.t('react.shop.auth.sign_in.magic_link_description')
      : signInDescription

  const forgotOrExpireLinkTitle = isMagicLinkExpired
    ? I18n.t('react.shop.auth.sign_in.request_new_link')
    : I18n.t('react.shop.auth.sign_in.forgot_password')
  const linkTitle = loginViaMagicLink
    ? I18n.t('react.shop.auth.login_with_email_and_password')
    : forgotOrExpireLinkTitle
  const link = loginViaMagicLink || isMagicLinkExpired ? `/s/${username}/sign_in` : `/s/${username}/password/new`

  const confirmationPageLink = `/s/${username}/confirmation/new`
  const isMagicLinkAppActive = isAppActive('magic_link')

  return [
    {
      data,
      formDescription,
      formTitle,
      isEmailValid,
      isMagicLinkAppActive,
      isPasswordValid,
      isValid,
      link,
      linkNotExpiredAndNotSent,
      linkTitle,
      loading,
      mobileView,
      touched,
      confirmationPageLink,
    },
    {
      handleInput,
      handleSubmit,
      toggleMagicLink,
    },
  ]
}
